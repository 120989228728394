@import 'tailwindcss/base';
@import 'tailwindcss/utilities';
@import 'tailwindcss/components';

@font-face {
  font-family: 'FontUp';
  src: local('FontUp'), url(../public/fonts/CFAstyStd-Light.otf) format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'FontUp';
  src: url(../public/fonts/CFAstyStd-LightOblique.otf) format('opentype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'FontUp';
  src: local('FontUp'), url(../public/fonts/CFAstyStd-Book.otf) format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'FontUp';
  src: url(../public/fonts/CFAstyStd-BookOblique.otf) format('opentype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'FontUp';
  src: url(../public/fonts/CFAstyStd-Medium.otf) format('opentype');
  font-weight: bold;
  font-style: normal
}

@font-face {
  font-family: 'FontUp';
  src: url(../public/fonts/CFAstyStd-MediumOblique.otf) format('opentype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'FontUp';
  src: url(../public/fonts/CFAstyStd-Bold.otf) format('opentype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'FontUp';
  src: url(../public/fonts/CFAstyStd-BoldOblique.otf) format('opentype');
  font-weight: 800;
  font-style: italic;
}

.MuiDataGrid-row.row-good-good{
  background: #f67a7a;
}
.MuiDataGrid-row.row-good-good:hover{
  background: #f39e9e!important;
}
.MuiDataGrid-row.row-bad-bad{
  background: lightgreen;
}
.MuiDataGrid-row.row-bad-bad:hover{
  background: #b1f1b1!important;
}

/*.MuiDataGrid-row.row-bad-bad:hover.Mui-selected, .MuiDataGrid-row.row-bad-bad:hover.Mui-selected, .MuiDataGrid-row.row-good-good.Mui-selected, .MuiDataGrid-row.row-bad-bad.Mui-selected{
  background: rgba(255, 153, 0, 0.08)!important;
}*/

.non-editable-cell{
  outline: none!important;
  cursor: not-allowed;
  color: #2f2f2f;
}
.MuiDataGrid-cell--editable , .non-editable-cell{
  border-left: 1px solid #dcdccd;
}
.border-right-cell{
  border-left: 1px solid #dcdccd;
}
.non-editable-cell-company{
  outline: none!important;
  cursor: pointer;
  font-weight: 600;
  color: #363535;
}
.non-editable-cell-employee{
  outline: none!important;
  cursor: pointer;
  color: #464646;
}

.font-bold{
  font-weight: bold;
  color: #585656;
}

html {
  display: flex;
  flex-direction: column;
  background-color: #f5b143;
  margin: 0;
  font-family: 'FontUp' , -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
body{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
#root{
  flex-grow: 1;
  height: 100%;
  background-color: #f5b143;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.animation-wrapper:hover .logo-container .logo{
  animation: logo-animation 8s infinite;
}
.animation-wrapper{}
.logo-container {
  position: relative;
  width: 300px;
  margin: 0 auto;
}
.logo {
  position: absolute;
  top: 0;
  left: 119px;
}

@keyframes logo-animation {
  0% {
    transform: scale(1) rotate(0deg);
  }
  10%{
    transform: translate(0, 0) rotate(-45deg) scale(1.15);
  }
  30% {
    transform: translate(131px, 0) rotate(90deg) scale(1.15);
  }
  55% {
    transform: translate(-131px, 0px) rotate(-180deg) scale(1.15);
  }
  85% {
    transform: translate(0, 0px) rotate(0deg) scale(1.15);
  }
  100% {
    transform: translate(0, 0) rotate(360deg) scale(1);
  }
}

